import React from 'react';
import classnames from 'classnames';
import * as css from './Section.module.css';

const Section = ({ observe, children, className, style }) => {
  return (
    <div
      data-observe={observe}
      className={classnames(css.root, className)}
      style={style}
    >
      {children}
    </div>
  );
};

export default Section;
