import { useEffect } from 'react';
import { passiveArg } from '../utils';
import { useObserveStore } from '../utils/store';

/**
 * Calculates the progress of every element with the specified classname
 * This version calculates only the elements that are on the screen.
 */
/*
export const useObserve = () => {
  useEffect(() => {
    // Tracks visible els on the screen
    const visible = [];

    // Hash map of all observed els keyed by name
    const observed = {};

    // Add and remove els from the visible array
    const onObserve = entries => {
      for (let i = 0; i < entries.length; i++) {
        const att = entries[i].target.getAttribute('data-observe');
        if (entries[i].isIntersecting) {
          visible.push(att);
        } else {
          const idx = visible.indexOf(att);
          if (idx > -1) {
            visible.splice(visible.indexOf(att), 1);
          }
        }
      }
    };

    // Calculate metrics for all these names elements
    const calculate = names => {
      const observations = {};

      for (let i = 0; i < names.length; i++) {
        const el = observed[names[i]];
        const bounds = el.getBoundingClientRect();
        observations[names[i]] = {
          top: bounds.top,
          height: bounds.height
        };
      }

      useObserveStore.setState({ observations });
    };

    // Calculate progress of all visible els
    const onScroll = () => {
      calculate(visible);
    };

    // Find and observe all the els
    const els = document.querySelectorAll('[data-observe]');
    const observer = new IntersectionObserver(onObserve);

    for (let i = 0; i < els.length; i++) {
      const att = els[i].getAttribute('data-observe');
      observed[att] = els[i];
      observer.observe(els[i]);
    }

    // Set the initial state for all elements
    // Hacky timeout because the browser hasn't rendered everything yet.
    setTimeout(() => calculate(Object.keys(observed)), 50);
    setTimeout(() => calculate(Object.keys(observed)), 500);

    window.addEventListener('scroll', onScroll, passiveArg);

    return () => {
      window.removeEventListener('scroll', onScroll, passiveArg);
      observer.disconnect();
    };
  }, []);
};*/

/**
 * This version tracks every element on every scroll
 */
export const useObserve = () => {
  useEffect(() => {
    // Hash map of all observed els keyed by name
    const observed = {};

    // Find and observe all the els
    const els = document.querySelectorAll('[data-observe]');
    for (let i = 0; i < els.length; i++) {
      const att = els[i].getAttribute('data-observe');
      observed[att] = els[i];
    }

    // Calculate metrics for all these names elements
    const calculate = () => {
      const observations = {};

      for (const name in observed) {
        const el = observed[name];
        const bounds = el.getBoundingClientRect();
        observations[name] = {
          top: bounds.top,
          height: bounds.height
        };
      }

      useObserveStore.setState({ observations });
    };

    // Set the initial state for all elements
    // Hacky timeout because the browser hasn't rendered everything yet.
    setTimeout(() => calculate(), 50);
    setTimeout(() => calculate(), 500);

    window.addEventListener('scroll', calculate, passiveArg);
    window.addEventListener('resize', calculate, passiveArg);

    return () => {
      window.removeEventListener('scroll', calculate, passiveArg);
      window.removeEventListener('resize', calculate, passiveArg);
    };
  }, []);
};

/**
 * Used to read the observation from a single element
 */
export const useObservation = (section, onMetrics) => {
  useEffect(() => {
    const unsubscribe = useObserveStore.subscribe(
      onMetrics,
      state => state.observations[section]
    );
    return () => {
      unsubscribe();
    };
  }, [section, onMetrics]);
};

/**
 * Used to read the observations from all elements
 */
export const useObservations = onMetrics => {
  useEffect(() => {
    const unsubscribe = useObserveStore.subscribe(
      onMetrics,
      state => state.observations
    );
    return () => {
      unsubscribe();
    };
  }, [onMetrics]);
};
