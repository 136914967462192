import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/Layout';
import Cursor from '../components/Cursor';
import Section from '../components/Section';
import Splash from '../components/Splash';
import Circle from '../components/Circle';
import CircleImage from '../components/CircleImage';
import WhatWeDo from '../components/WhatWeDo';
import HowWeDoIt from '../components/HowWeDoIt';
import WhoWeDoItFor from '../components/WhoWeDoItFor';
import WhatWeAreBuilding from '../components/WhatWeAreBuilding';
import WhoWeAre from '../components/WhoWeAre';
import HowWeGiveBack from '../components/HowWeGiveBack';

import { useCircleData, useImages } from '../hooks';
import { useObserve } from '../hooks/observe';

import * as css from '../styles/pages/index.module.css';

const imagesQuery = graphql`
  query {
    images: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativeDirectory: { eq: "circles" }
      }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`;

const IndexPage = () => {
  const circleData = useCircleData();
  const data = useStaticQuery(imagesQuery);
  const images = useImages(data.images.nodes);
  useObserve();
  return (
    <Layout className={css.root}>
      <Cursor />
      <Section observe="intro" className={css.intro}>
        <Circle isIntro circleData={circleData} />
        <Splash isIntro />
      </Section>
      <div className={css.cols}>
        <div className={css.right}>
          <CircleImage
            section="who-we-are"
            image={images.metro}
            circleData={circleData}
          />
          <WhoWeAre />
          <CircleImage
            section="what-we-do"
            image={images.eliasonbridge}
            circleData={circleData}
          />
          <WhatWeDo />
          <CircleImage
            section="what-we-are-building"
            image={images.shutterstock_1820660447}
            circleData={circleData}
          />
          <WhatWeAreBuilding />
          <CircleImage
            section="how-we-do-it"
            image={images['daniele-salutari-IicHpgjMPKs-unsplash']}
            circleData={circleData}
          />
          <HowWeDoIt />
          <CircleImage
            section="who-we-do-it-for"
            image={images.understanding}
            circleData={circleData}
          />
          <WhoWeDoItFor />
          <CircleImage
            section="how-we-give-back"
            image={images['gordon-plant-JL45F2yi3Zg-unsplash']}
            circleData={circleData}
          />
          <HowWeGiveBack />
        </div>
      </div>
      <Section observe="outro" className={css.outro}>
        <Circle circleData={circleData} />
        <Splash />
      </Section>
    </Layout>
  );
};

export default IndexPage;
