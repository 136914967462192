import React, { memo, useCallback } from 'react';
import Section from './Section';
import SectionParagraph from './SectionParagraph';
import { useObservation } from '../hooks/observe';
import { useSpring, animated } from '@react-spring/web';
import { map, getWindowSize } from '../utils';

import * as css from './HowWeDoIt.module.css';

const HowWeDoIt = () => {
  const [{ progress }, animation] = useSpring(
    () => ({
      progress: 0,
      immediate: true
    }),
    []
  );

  const onMetrics = useCallback(
    metrics => {
      if (metrics) {
        const win = getWindowSize();
        animation.set({
          progress: map(metrics.top, win[1], -metrics.height, 0, 1)
        });
      }
    },
    [animation]
  );

  useObservation('venn', onMetrics);

  const leftTransform = progress.to(
    p => `translateX(${map(p, 0, 1, -150, 0)}px)`
  );

  const rightTransform = progress.to(
    p => `translateX(${map(p, 0, 1, 150, 0)}px)`
  );

  return (
    <Section observe="how-we-do-it">
      <SectionParagraph title="How we do it">
        <p>
          Problem-solving from both a business and human perspective – initiated
          by scoping excellence and delivered through a flexible, high-touch
          engagement model
        </p>
      </SectionParagraph>
      <div className={css.graphic}>
        <svg viewBox="0 0 600 270" className={css.svg} data-observe="venn">
          <animated.circle
            cx={300}
            cy={135}
            r={135}
            fill="var(--forest)"
            className={css.circle}
            style={{ transform: leftTransform }}
          />
          <animated.circle
            cx={300}
            cy={135}
            r={135}
            fill="var(--forest)"
            className={css.circle}
            style={{ transform: rightTransform }}
          />
          <Label
            word1="Business"
            word2="perspective"
            x={55}
            color="var(--slate)"
          />
          <Label
            word1="Human"
            word2="perspective"
            x={545}
            color="var(--slate)"
          />
          <Label
            word1="Sustainable"
            word2="business strategy"
            x={302}
            color="white"
          />
        </svg>
      </div>
    </Section>
  );
};

const Label = ({ word1, word2, x, color }) => {
  return (
    <g
      textAnchor="middle"
      transform={`translate(${x}, 135)`}
      fontSize={20}
      fill={color}
    >
      <text>{word1}</text>
      {word2 && <text y="20">{word2}</text>}
    </g>
  );
};

export default memo(HowWeDoIt);
