import React, { memo, useCallback } from 'react';
import Section from './Section';
import SectionParagraph from './SectionParagraph';
import { useObservation } from '../hooks/observe';
import { useSpring, animated } from '@react-spring/web';
import { map, getWindowSize } from '../utils';
import Easing from '../utils/easing';

import * as css from './WhatWeAreBuilding.module.css';

const keywords = [
  ['Traditional teams', 'Flexible teams'],
  ['Standard\u200B frameworks', 'Customized\u200B approach'],
  ['Answer\u2011first', 'Co\u2011creation'],
  ['Siloed problem\u2011solving', 'Holistic problem\u2011solving'],
  ['Fast delivery', 'Sustainable anchoring'],
  ['Leading by title', 'Leading\u00A0by initiative'],
  ['Up\u00A0or\u00A0out', 'Stay\u00A0and\u00A0play'],
  ['CSR\u00A0compliance', 'Sustainability leadership'],
  ['Owner\u00A0shares for\u00A0a\u00A0few', 'Owner\u00A0shares for all']
];

const WhatWeAreBuilding = () => {
  const [{ progress }, animation] = useSpring(
    () => ({
      progress: 0,
      immediate: true
    }),
    []
  );

  const onMetrics = useCallback(
    metrics => {
      if (metrics) {
        const win = getWindowSize();
        animation.set({
          progress: map(
            metrics.top,
            win[1] / 2,
            -(metrics.height / 2),
            0,
            1,
            Easing.inOutQuad
          )
        });
      }
    },
    [animation]
  );

  useObservation('what-we-are-building', onMetrics);

  return (
    <Section observe="what-we-are-building">
      <SectionParagraph title="What we are building">
        <p>
          A human-first, purpose-driven strategy consulting and investment
          company
        </p>
      </SectionParagraph>
      <div className={css.graphic}>
        {keywords.map(keyword => (
          <Keyword
            key={keyword}
            from={keyword[0]}
            to={keyword[1]}
            progress={progress}
          />
        ))}
      </div>
    </Section>
  );
};

const Keyword = memo(({ from, to, progress }) => {
  return (
    <div className={css.keyword}>
      <div className={css.word}>
        <span>{from}</span>
      </div>
      <div className={css.arrow}>
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="16">
          <defs>
            <marker
              id="arrowhead"
              markerWidth="4"
              markerHeight="7"
              refX="4"
              refY="3.5"
              orient="auto"
              strokeLinecap="round"
            >
              <path
                d="M 0.5 0.5 L 3.5 3.5 L 0.5 6.5"
                fill="none"
                stroke="var(--blush)"
              />
            </marker>
          </defs>
          <animated.line
            x1="0"
            y1="8"
            x2={progress.to(p => `${map(p, 0, 1, 10, 100)}%`)}
            y2="8"
            stroke="var(--blush)"
            strokeWidth="2"
            markerEnd="url(#arrowhead)"
          />
        </svg>
      </div>
      <div className={css.word}>
        <span>{to}</span>
      </div>
    </div>
  );
});

export default memo(WhatWeAreBuilding);
