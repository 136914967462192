import { useState, useEffect, useMemo } from 'react';
import { getWindowSize, passiveArg } from '../utils';
import { breakpoints } from '../utils/breakpoints';

/**
 * Returns the size of the current window.
 * Defaults to null/null to server and client matches
 */
const defaultWin = [null, null];

export const useWindowSize = () => {
  const [win, setWin] = useState(defaultWin);
  const handleResize = () => {
    window.requestAnimationFrame(() => {
      setWin(getWindowSize());
    });
  };
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize, passiveArg);
    return () => {
      window.removeEventListener('resize', handleResize, passiveArg);
    };
  }, []);
  return win;
};

/**
 * Flips a boolean when the code is rendered in the browser.
 */
export const useClientRender = nodes => {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => setIsClient(true), []);
  return isClient;
};

/**
 * Takes an array of images nodes and makes a hashed object based on their names
 */
export const useImages = nodes => {
  return useMemo(() => {
    const images = {};
    for (let i = 0; i < nodes.length; i++) {
      images[nodes[i].name] = nodes[i].childImageSharp.gatsbyImageData;
    }
    return images;
  }, [nodes]);
};

/**
 * Flips a boolean when the user loads the page and doesn't do
 * anything for x ms.
 */
export const useIdle = ms => {
  const [idle, setIdle] = useState(false);
  useEffect(() => {
    const onScroll = () => {
      setIdle(false);
      window.removeEventListener('scroll', onScroll, passiveArg);
    };

    window.addEventListener('scroll', onScroll, passiveArg);

    if (window.scrollY === 0) {
      let timeout = setTimeout(() => {
        if (window.scrollY === 0) {
          setIdle(true);
        }
      }, ms);
      return () => {
        clearTimeout(timeout);
        window.removeEventListener('scroll', onScroll, passiveArg);
      };
    }
  }, [ms]);
  return idle;
};

/**
 * Flips a boolean when fonts are loaded
 */
export const useFontsReady = (ms = 0) => {
  const [fontsReady, setFontsReady] = useState(false);
  useEffect(() => {
    let mounted = true;
    let timeout;
    document.fonts.ready.then(() => {
      if (mounted) {
        if (ms === 0) {
          setFontsReady(true);
        } else {
          timeout = setTimeout(() => {
            setFontsReady(true);
          }, ms);
        }
      }
    });
    return () => {
      mounted = false;
      clearTimeout(timeout);
    };
  }, [ms]);
  return fontsReady;
};

/**
 * Calculate things needed for the circle animations
 */

export const useCircleData = () => {
  const [winWidth, winHeight] = useWindowSize();
  return useMemo(() => {
    if (!winWidth || !winHeight) {
      return null;
    }

    const isColumns = winWidth > breakpoints.medium;

    const data = {
      // Multiplier of winHeight used for animation speed. Higher is slower.
      animationSpeed: 1.3,
      winWidth,
      winHeight
    };

    if (isColumns) {
      // This should be set to whatever the min-width of .right is set to
      const contentMinWidth = 650;

      // This is calculating the width of the .right column
      const contentWidth = Math.max(contentMinWidth, winWidth / 2);

      // Diameter of the circle is relative to win height on two-column layout
      data.diameter = (winHeight / 12) * 10;

      // With two columns, the width/height can be larger than the left
      // column and moved to the left two preserve a larger circle
      data.width = Math.max(winHeight, winWidth / 2);
      data.height = Math.min(data.width, winHeight);
      data.left = -(data.width - (winWidth - contentWidth));
      data.translateX = -data.left + (winWidth / 2 - data.width / 2);
    } else {
      data.diameter = (Math.min(winWidth, winHeight) / 12) * 10;
      data.left = 0;
      data.width = winWidth;
      data.height = winWidth;
      data.translateX = 0;
    }

    return data;
  }, [winWidth, winHeight]);
};
