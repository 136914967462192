// extracted by mini-css-extract-plugin
export var root = "WhoWeAre-module--root--2kHMF";
export var graphic = "WhoWeAre-module--graphic--2ekCq";
export var text = "WhoWeAre-module--text--351vS";
export var person = "WhoWeAre-module--person--L5P0v";
export var image = "WhoWeAre-module--image--1CSPh";
export var details = "WhoWeAre-module--details--2OfiQ";
export var contact = "WhoWeAre-module--contact--1n546";
export var contactItem = "WhoWeAre-module--contactItem--3LLKM";
export var readMore = "WhoWeAre-module--readMore--33Lpt";
export var joinUs = "WhoWeAre-module--joinUs--1YXLh";