import React, { useCallback, memo } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { map } from '../utils';
import Easing from '../utils/easing';
import { breakpoints } from '../utils/breakpoints';
import { useObservation } from '../hooks/observe';

import * as css from './CircleImage.module.css';

/**
  Used to render each image with circle animation
**/
const CircleImage = ({ section, image, circleData }) => {
  const { width, height, left, diameter, winWidth, winHeight, animationSpeed } =
    circleData || {};

  const [{ progress }, animation] = useSpring(
    () => ({
      progress: 0,
      immediate: true
    }),
    []
  );

  const onMetrics = useCallback(
    metrics => {
      if (metrics) {
        const isColumns = winWidth > breakpoints.medium;
        animation.set({
          progress: isColumns
            ? // Columns shows the image animation based on the section
              map(metrics.top, winHeight * animationSpeed, 0, 0, 1)
            : // No columns shows the image animation based on the image
              map(
                metrics.top,
                winHeight * animationSpeed + height,
                height,
                0,
                1
              )
        });
      }
    },
    [animation, winWidth, winHeight, animationSpeed, height]
  );

  useObservation(section, onMetrics);

  if (!width || !height) {
    return null;
  }

  return (
    <div
      className={css.root}
      style={{ width, height, transform: `translateX(${left}px)` }}
    >
      <Picture
        image={image}
        style={{
          opacity: progress.to(p => map(p, 0.5, 1, 0, 1, Easing.inOutQuad))
        }}
      />
      <Picture
        image={image}
        style={{
          opacity: progress.to(p => map(p, 0, 0.3, 0, 1, Easing.inOutQuad)),
          clipPath: `circle(${diameter / 2}px)`,
          transform: progress.to(
            p => `rotate(${map(p, 0, 0.7, 15, 0, Easing.inOutQuad)}deg)`
          )
        }}
      />
    </div>
  );
};

const Picture = ({ image, style }) => {
  return (
    <picture>
      {image.images.sources.map(source => (
        <source key={source.type} {...source} />
      ))}
      <animated.img alt="" style={style} {...image.images.fallback} />
    </picture>
  );
};

export default memo(CircleImage);
