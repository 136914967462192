import React from 'react';
import Logo from './Logo';
import { AiFillLinkedin, AiFillMail, AiFillInstagram } from 'react-icons/ai';
import classnames from 'classnames';
import { useIdle } from '../hooks';
import { useWindowSize, useFontsReady } from '../hooks';

import * as css from './Splash.module.css';

const introText = (
  <>
    A human-first, purpose-driven strategy
    <br />
    consulting and investment company
  </>
);

const outroText = (
  <>
    Esplanaden 8C, 4.
    <br />
    1263 Copenhagen K
    <br />
    CVR 42589950
    <br />
    <a href="https://www.linkedin.com/company/rounddk">
      <AiFillLinkedin className={css.space} />
    </a>
    <a href="mailto:round@round.dk">
      <AiFillMail className={css.space} />
    </a>
    <a href="https://www.instagram.com/round.dk/">
      <AiFillInstagram />
    </a>
     <br />
    <a href="/Round-Privacy.pdf">Privacy policy</a>
    <br />
  </>
);

const Splash = ({ isIntro }) => {
  const [winWidth] = useWindowSize();
  const fontsReady = useFontsReady();

  const text = isIntro ? introText : outroText;

  if (winWidth === null || !fontsReady) {
    return <SplashServer text={text} />;
  } else {
    return <SplashClient text={text} isIntro={isIntro} />;
  }
};

const SplashServer = ({ text }) => {
  return (
    <div className={css.server}>
      <h1>Round</h1>
      <p>{text}</p>
    </div>
  );
};

const SplashClient = ({ isIntro, text }) => {
  const idle = useIdle(2000);

  return (
    <div className={classnames(css.root, { [css.outro]: !isIntro })}>
      <div className={css.align}>
        <Logo className={css.logo} />
        <p className={css.text}>{text}</p>
      </div>
      <div
        className={css.scrollIndicator}
        style={{ display: idle ? 'block' : 'none' }}
      />
    </div>
  );
};

export default Splash;
