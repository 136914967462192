import React, { useCallback, memo } from 'react';
import classnames from 'classnames';
import { useSpring, animated } from '@react-spring/web';
import { map } from '../utils';
import { breakpoints } from '../utils/breakpoints';
import { useObservation } from '../hooks/observe';

import * as css from './Circle.module.css';

/**
  Intro and outro circle animation
**/
export const Circle = memo(({ isIntro, circleData }) => {
  if (!circleData) {
    // For SSR render and when the window dimensions are not yet known
    // we render a circle done in pure CSS.
    return (
      <div className={css.server}>
        <div className={css.serverCircle} />
      </div>
    );
  } else {
    // Now we can rely on the JS-calculated data.
    return <CircleClient isIntro={isIntro} circleData={circleData} />;
  }
});

const CircleClient = memo(({ isIntro, circleData }) => {
  const {
    width,
    height,
    left,
    diameter,
    winWidth,
    winHeight,
    translateX,
    animationSpeed
  } = circleData;

  const [{ progress }, animation] = useSpring(
    () => ({
      progress: 0,
      immediate: true
    }),
    []
  );

  const isColumns = winWidth > breakpoints.medium;

  const onMetrics = useCallback(
    metrics => {
      if (metrics) {
        if (isIntro) {
          animation.set({
            progress: map(
              metrics.top,
              0,
              -(metrics.height - winHeight * animationSpeed),
              0,
              1
            )
          });
        } else {
          animation.set({
            progress: map(
              metrics.top,
              winHeight,
              -(metrics.height - winHeight),
              0,
              1
            )
          });
        }
      }
    },
    [animation, winHeight, animationSpeed, isIntro]
  );

  useObservation(isIntro ? 'intro' : 'outro', onMetrics);

  const transform = isIntro
    ? progress.to(p => `translateX(${map(p, 0, 1, left + translateX, left)}px)`)
    : progress.to(
        p => `translateX(${map(p, 0.3, 1, left, left + translateX)}px)`
      );

  const opacity = isIntro ? null : progress.to(p => map(p, 0, 0.3, 0, 1));

  return (
    <animated.div
      className={classnames(css.circle, { [css.outro]: !isIntro })}
      style={{
        width,
        // Override the default image height to make circle vertical center on mobile
        height: isColumns ? height : '100vh',
        opacity,
        clipPath: `circle(${diameter / 2}px)`,
        transform
      }}
    />
  );
});

export default Circle;
