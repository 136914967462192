import React, { memo, useCallback } from 'react';
import Section from './Section';
import SectionParagraph from './SectionParagraph';
import { useObservation } from '../hooks/observe';
import { useSpring, animated } from '@react-spring/web';
import { map, randomBetween, getWindowSize } from '../utils';

import * as css from './WhoWeDoItFor.module.css';

const circleDefs = [
  { cx: 338, cy: 43, r: 12, fill: 'var(--forest)' },
  { cx: 364, cy: 85, r: 20, fill: 'var(--forest)' },
  { cx: 406, cy: 115, r: 20, fill: 'var(--forest)' },
  { cx: 376, cy: 35, r: 10, fill: 'var(--forest)' },
  { cx: 349, cy: 136, r: 20, fill: 'var(--forest)' },
  { cx: 411, cy: 58, r: 15, fill: 'var(--forest)' },
  { cx: 411, cy: 166, r: 15, fill: 'var(--forest)' },
  { cx: 445, cy: 201, r: 25, fill: 'var(--forest)' },
  { cx: 468, cy: 39, r: 20, fill: 'var(--blush)' },
  { cx: 455, cy: 89, r: 15, fill: 'var(--blush)' },
  { cx: 462, cy: 135, r: 20, fill: 'var(--blush)' },
  { cx: 497, cy: 80, r: 15, fill: 'var(--blush)' },
  { cx: 493, cy: 175, r: 20, fill: 'var(--forest)' },
  { cx: 516, cy: 126, r: 25, fill: 'var(--blush)' },
  { cx: 536, cy: 72, r: 15, fill: 'var(--blush)' },
  { cx: 542, cy: 177, r: 20, fill: 'var(--forest)' },
  { cx: 537, cy: 226, r: 15, fill: 'var(--forest)' },
  { cx: 562, cy: 32, r: 15, fill: 'var(--blush)' },
  { cx: 562, cy: 113, r: 15, fill: 'var(--blush)' },
  { cx: 603, cy: 19, r: 10, fill: 'var(--blush)' },
  { cx: 595, cy: 80, r: 25, fill: 'var(--blush)' },
  { cx: 597, cy: 141, r: 20, fill: 'var(--forest)' },
  { cx: 588, cy: 246, r: 20, fill: 'var(--forest)' },
  { cx: 364, cy: 191, r: 20, fill: 'var(--forest)' },
  { cx: 304, cy: 161, r: 12, fill: 'var(--forest)' },
  { cx: 306, cy: 204, r: 15, fill: 'var(--forest)' },
  { cx: 405, cy: 240, r: 20, fill: 'var(--forest)' },
  { cx: 280, cy: 40, r: 20, fill: 'var(--forest)' },
  { cx: 314, cy: 70, r: 12, fill: 'var(--forest)' }
];

const WhoWeDoItFor = () => {
  const [{ progress }, animation] = useSpring(
    () => ({
      progress: 0,
      immediate: true
    }),
    []
  );

  const onMetrics = useCallback(
    metrics => {
      if (metrics) {
        const win = getWindowSize();
        animation.set({
          progress: map(metrics.top, win[1] / 2, -(metrics.height / 2), 0, 1)
        });
      }
    },
    [animation]
  );

  useObservation('who-we-do-it-for', onMetrics);

  const circles = [];
  for (let i = 0; i < circleDefs.length; i++) {
    const offset = randomBetween(0, 0.4);
    circles.push(
      <animated.circle
        key={`c-${i}`}
        {...circleDefs[i]}
        style={{
          opacity: progress.to(p => map(p, offset, offset + 0.6, 0, 1))
        }}
      />
    );
  }

  return (
    <Section observe="who-we-do-it-for">
      <SectionParagraph title="Who we do it for">
        <p>
          We partner with progressive clients or clients with progressive
          projects
        </p>
      </SectionParagraph>
      <div className={css.graphic}>
        <svg viewBox="0 0 638 422" className={css.svg}>
          <defs>
            <marker
              id="arrowhead2"
              markerWidth="4"
              markerHeight="7"
              refX="4"
              refY="3.5"
              orient="auto"
              strokeLinecap="round"
            >
              <path
                d="M 0.5 0.5 L 3.5 3.5 L 0.5 6.5"
                fill="none"
                stroke="black"
              />
            </marker>
          </defs>
          <line
            x1="32"
            y1="386"
            x2="32"
            y2="21"
            stroke="black"
            strokeWidth="2"
            markerEnd="url(#arrowhead2)"
          />
          <line
            x1="32"
            y1="385"
            x2="611"
            y2="385"
            stroke="black"
            strokeWidth="2"
            markerEnd="url(#arrowhead2)"
          />
          <line x1="225" y1="386" x2="225" y2="26" className={css.dashed} />
          <line x1="418" y1="386" x2="418" y2="26" className={css.dashed} />
          <line x1="32" y1="143" x2="611" y2="143" className={css.dashed} />
          <line x1="32" y1="264" x2="611" y2="264" className={css.dashed} />
          <text x="15" y="190" transform="rotate(-90 15 190)">
            Progressive projects
          </text>
          <text x="460" y="408">
            Progressive clients
          </text>
          <g>{circles}</g>
        </svg>
      </div>
    </Section>
  );
};

export default memo(WhoWeDoItFor);
