import React from 'react';
import classnames from 'classnames';

import * as css from './SectionParagraph.module.css';

const SectionParagraph = ({ title, children, className }) => {
  return (
    <div className={classnames(css.root, className)}>
      <h2>{title}</h2>
      {children}
    </div>
  );
};

export default SectionParagraph;
