// These must be kept up to date with the CSS custom media
export const breakpoints = {
  small: 650,
  medium: 800
};

export const breakpointNames = Object.keys(breakpoints);

export const getBreakpoint = width => {
  if (width === null) {
    return null;
  }
  for (let i = 0; i < breakpointNames.length; i++) {
    if (width <= breakpoints[breakpointNames[i]]) {
      return breakpointNames[i];
    }
  }
  return 'largest';
};

// A function that returns true/false as to whether the current
// breakpoint is covered by the needed breakpoint.
export const isBreakpoint = (breakpoint, needed) =>
  breakpointNames.indexOf(breakpoint) <= breakpointNames.indexOf(needed);

// This function receives the name of the current breakpoint
// as well as an object like this:
// { largest: 1, medium: 2 }
// It returns the first value from the breakpoint that is current
// or below. This is why it is required to have a "largest" key for fallback.
export const getBreakpointValues = (breakpoint, values) => {
  if (!values.hasOwnProperty('largest')) {
    console.error('pick called without largest', values);
  }

  // If there is a value with this exact breakpoint
  if (values.hasOwnProperty(breakpoint)) {
    return values[breakpoint];
  }

  // Otherwise look
  const idx = breakpointNames.indexOf(breakpoint);
  for (let i = idx; i < breakpointNames.length; i++) {
    if (values.hasOwnProperty(breakpointNames[i])) {
      return values[breakpointNames[i]];
    }
  }

  throw new Error(
    `Breakpoint not found. Current: ${breakpoint}, Values: ${JSON.stringify(
      values
    )}`
  );
};
