const Easing = {
  // accelerating from zero velocity
  inQuad: t => {
    return t * t;
  },

  // decelerating to zero velocity
  outQuad: t => {
    return t * (2 - t);
  },

  // acceleration until halfway, then deceleration
  inOutQuad: t => {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  },

  // accelerating from zero velocity
  inCubic: t => {
    return t * t * t;
  },

  // decelerating to zero velocity
  outCubic: t => {
    return --t * t * t + 1;
  },

  // acceleration until halfway, then deceleration
  inOutCubic: t => {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
  },

  // accelerating from zero velocity
  inQuart: t => {
    return t * t * t * t;
  },

  // decelerating to zero velocity
  outQuart: t => {
    return 1 - --t * t * t * t;
  },

  // acceleration until halfway, then deceleration
  inOutQuart: t => {
    return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
  },

  // accelerating from zero velocity
  inQuint: t => {
    return t * t * t * t * t;
  },

  // decelerating to zero velocity
  outQuint: t => {
    return 1 + --t * t * t * t * t;
  },

  // acceleration until halfway, then deceleration
  inOutQuint: t => {
    return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
  }
};

export default Easing;
