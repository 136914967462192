import React, { memo, useCallback } from 'react';
import Section from './Section';
import SectionParagraph from './SectionParagraph';
import { useObservation } from '../hooks/observe';
import { useSpring, animated } from '@react-spring/web';
import { map, radians, getWindowSize } from '../utils';

import * as css from './WhatWeDo.module.css';

const terms = [
  'Strategy',
  'Purpose',
  'Sustainability',
  'Culture',
  'People management',
  'Go-to-market',
  'Value proposition',
  'Brand',
  'M&A',
  'Strategy execution',
  'Change facilitation',
  'operating model'
];

const WhatWeDo = () => {
  const [{ progress }, animation] = useSpring(
    () => ({
      progress: 0,
      immediate: true
    }),
    []
  );

  const onMetrics = useCallback(
    metrics => {
      if (metrics) {
        const win = getWindowSize();
        animation.set({
          progress: map(metrics.top, win[1], -metrics.height, 0, 1)
        });
      }
    },
    [animation]
  );

  useObservation('wheel', onMetrics);

  const dotSpacing = 360 / terms.length;
  const radius = 180;
  const labelsWidth = 1650;
  const labelSpacing = labelsWidth / (terms.length - 1);

  const dots = [];
  const labelsTop = [];
  const labelsBottom = [];

  for (let i = 0; i < terms.length; i++) {
    // Dots
    const x = Math.cos(radians(i * dotSpacing)) * radius;
    const y = Math.sin(radians(i * dotSpacing)) * radius;
    dots.push(<circle key={`c-${i}`} cx={x} cy={y} r={10} />);

    // Labels top
    labelsTop.push(
      <text
        textAnchor="middle"
        x={(terms.length - 1 - i) * labelSpacing}
        key={terms[i]}
      >
        {terms[i]}
      </text>
    );

    // Labels bottom
    labelsBottom.push(
      <text textAnchor="middle" x={i * labelSpacing} key={terms[i]}>
        {terms[i]}
      </text>
    );
  }

  const ringTransform = progress.to(
    p => `translate(200 240) rotate(${map(p, 0, 1, -90, 270 - dotSpacing)})`
  );

  const labelsTopTransform = progress.to(
    p => `translate(${200 - labelsWidth - labelSpacing + p * labelsWidth} 25)`
  );

  const labelsBottomTransform = progress.to(
    p => `translate(${200 - p * labelsWidth} 460)`
  );

  return (
    <Section observe="what-we-do">
      <SectionParagraph title="What we do">
        <p>
          We create sustainable strategies and initiatives to solve problems
          that shape business and society for the better
        </p>
      </SectionParagraph>
      <div className={css.graphic}>
        <svg viewBox="0 0 400 480" className={css.svg} data-observe="wheel">
          <defs>
            <linearGradient id="gradient">
              <stop offset="0%" stopColor="var(--mist)" stopOpacity="1" />
              <stop offset="25%" stopColor="var(--mist)" stopOpacity="0" />
              <stop offset="75%" stopColor="var(--mist)" stopOpacity="0" />
              <stop offset="100%" stopColor="var(--mist)" stopOpacity="1" />
            </linearGradient>
          </defs>

          <animated.g className={css.labels} transform={labelsTopTransform}>
            {labelsTop}
          </animated.g>
          <animated.g className={css.labels} transform={labelsBottomTransform}>
            {labelsBottom}
          </animated.g>
          <rect x="0" y="0" width="100%" height="100%" fill="url(#gradient)" />
          <animated.g fill="var(--blush)" transform={ringTransform}>
            {dots}
          </animated.g>
        </svg>
      </div>
    </Section>
  );
};

export default memo(WhatWeDo);
