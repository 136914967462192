import React, { memo, useCallback } from 'react';
import Section from './Section';
import SectionParagraph from './SectionParagraph';
import { useObservation } from '../hooks/observe';
import { useSpring, animated } from '@react-spring/web';
import { map, getWindowSize } from '../utils';

import * as css from './HowWeGiveBack.module.css';

const HowWeGiveBack = () => {
  const [{ progress }, animation] = useSpring(
    () => ({
      progress: 0,
      immediate: true
    }),
    []
  );

  const onMetrics = useCallback(
    metrics => {
      if (metrics) {
        const win = getWindowSize();
        animation.set({
          progress: map(metrics.top, win[1], -metrics.height, 0, 1)
        });
      }
    },
    [animation]
  );

  useObservation('joinCircles', onMetrics);

  const smallTransform = progress.to(
    p => `translateX(${map(p, 0, 1, 0, 150)}px)`
  );

  const largeTransform = progress.to(
    p => `translateX(${map(p, 0, 1, 0, -150)}px)`
  );

  return (
    <Section observe="how-we-give-back">
      <SectionParagraph title="How we contribute to a more sustainable world">
        <p>
          Over time, we will dedicate up to 20% of our annual revenue to social
          impact investments and low bono work for social impact start-ups or
          not-for-profit organizations
        </p>
      </SectionParagraph>
      <div className={css.graphic}>
        <svg
          viewBox="0 0 600 270"
          className={css.svg}
          data-observe="joinCircles"
        >
          <animated.circle
            cx={275}
            cy={135}
            r={135}
            fill="var(--slate)"
            style={{ transform: largeTransform }}
          />
          <animated.circle
            cx={300}
            cy={135}
            r={40}
            fill="var(--blush)"
            style={{ transform: smallTransform }}
          />
        </svg>
      </div>
    </Section>
  );
};

export default memo(HowWeGiveBack);
