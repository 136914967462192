import React from 'react';
import classnames from 'classnames';

import * as css from './Logo.module.css';

const Logo = ({ className, style }) => {
  return (
    <span className={classnames(css.root, className)} style={style}>
      Round
    </span>
  );
};

export default Logo;
